@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
:root {
  --dark: #1C2321;
  --accent: #3ca17e;
  --accenthover: #3b8664;
  --darkish: #5E6572;
  --lightish: #A9B4C2;
  --light: #EEF1EF;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 1.7rem;
}

a {
  text-decoration: none;
  color: #3ca17e;
  color: var(--accent);
}

a:hover {
  color: #A9B4C2;
  color: var(--lightish);
}

p {
  margin: 0.75rem 0;
}

main h1 {
  margin: 0.5rem 0;
}

h1 {
  line-height: 2.0rem;
}

th {
  text-align: left;
}

th, td {
  border-bottom: black 1px dotted;
  padding: 5px;
}

img {
  max-width: 1036px;
}

/* Utility Classes */

.input {
  padding: 6px 12px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}

.container {
  max-width: 1100px;
  margin: auto;
  padding: 1rem 2rem;
}

.tag {
  display: inline-block;
  background: #3ca17e;
  background: var(--accent);
  color: #EEF1EF;
  color: var(--light);
  line-height: 1.1rem;
  border-radius: 3px;
  padding: 5px;
  margin: 5px;
  font-size: 1.0rem;
  font-weight: normal;
}

.section-title {
  text-align: center;
  font-size: 2rem;
  margin: 1rem 0 1rem 0;
}

.section-description {
  text-align: center;
  font-size: 1.2rem;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}


.card-title {
  font-size: 1.6rem;
}

.card {
  text-align: center;
  margin: 0.5rem;
  width: 325px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn {
  color: #EEF1EF;
  color: var(--light);
  background-color: #3ca17e;
  background-color: var(--accent);
  padding: 6px 12px;
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}

.btn-wide {
  min-width: 100%;
}

.btn:hover {
  background-color: #3b8664;
  background-color: var(--accenthover);
}

.well {
  background: lightgray;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
}

.blog-image {
  max-width: 500px;
  display: block;
  margin: 20px auto;
}

/* Header */

header {
  display: flex;
  justify-content: space-between;
  background: #1C2321;
  background: var(--dark);
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  padding: 1rem;
}

header .home {
  font-size: 1.5rem;
}

header .nav {
  display: flex;
  list-style: none;
  align-items: center;
}

header .nav li {
  padding: 0 0.75rem;
  cursor: pointer;
}

/* Footer */

footer {
  background: #1C2321;
  background: var(--dark);
  color: #3ca17e;
  color: var(--accent);
}


/* Recipe Page */

.recipe-title {
  padding: 0 0 1.0rem 0;
}

.recipe-card-image {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 275px;
  padding: 0 0 1.0rem 0;
}

.recipe-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.recipe-text {
  max-width: 500px;
}

.recipe-large-image {
  border-radius: 10px;
}

.cook-container {
  display: block;
}

.cook-controls {
  display: flex;
  justify-content: space-between;
}

.controls {
  margin: 0 0 10px 0;
}

@media only screen and (max-width: 768px) {
  .recipe-container {
    flex-wrap: wrap-reverse;
  }
  .recipe-large-image {
    width: 350px;
  }
  .ingredient-list {
    margin: 0;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

